import React, { Fragment, memo, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { LoadingSpinnerButton } from '../components/loading';
import { getResourceText } from '../lib/language';
import { clearData, saveData, USER_KEY } from '../lib/store';
import { useApi } from '../hooks/api';

const ErrorMessage = memo(({ resourceKey }) =>
  <span className="login-container__error-message">{getResourceText(resourceKey)}</span>
);

const UpdatePassword = () => {
  const [nextPassword, setNextPassword] = useState('');
  const [nextPasswordError, setNextPasswordError] = useState(false);
  const [nextPasswordAgain, setNextPasswordAgain] = useState('');
  const [nextPasswordAgainError, setNextPasswordAgainError] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { updateUser: apiUpdateUser, getUser } = useApi();

  const validate = () => {
    setNextPasswordError(false);
    setNextPasswordAgainError(false);

    if (nextPassword.trim().length < 5) {
      setNextPasswordError(true);
      return false;
    }

    if (nextPassword !== nextPasswordAgain) {
      setNextPasswordAgainError(true);
      return false;
    }
    return true;
  };

  const updateUser = async (event) => {
    event.preventDefault();
    if (!validate()) return;

    setLoading(true);
    const { status } = await apiUpdateUser({ nextPassword });

    if (status === 200) {
      const { status: getUserStatus, json } = await getUser();

      if(getUserStatus === 200) {
        saveData(USER_KEY, JSON.stringify(json));
        await navigate('/dashboard');
      }
    } else {
      setError(true);
    }
    setLoading(false);
  };

  return (
    <Fragment>
      <h1 className="xlarge-heading login-container__main-heading">{getResourceText('loginMainHeading')}</h1>
      <h2 className="medium-heading">{getResourceText('updatePasswordHeading')}</h2>
      <p className="login-container-text">{getResourceText('updatePasswordText')}</p>
      <StaticImage
        src="../images/peri-with-maraccas.png"
        alt={getResourceText('professorPeriWelcomeAltText')}
        title="Professor Peri"
        height={300}
        placeholder="blurred"
        className="login-image login-image-password-update"
      />
      <form onSubmit={updateUser}>
        <label className="login-container__label-container">
          <span className="login-container__label">{getResourceText('newPassword')}</span>
          <input
            type="password"
            className="login-container__input"
            value={nextPassword}
            onChange={({ target: { value } }) => setNextPassword(value)}
          />
          {nextPasswordError ? <ErrorMessage resourceKey="newPasswordErrorMessage"/> : null}
        </label>
        <label className="login-container__label-container">
          <span className="login-container__label">{getResourceText('newPasswordAgain')}</span>
          <input
            type="password"
            className="login-container__input"
            value={nextPasswordAgain}
            onChange={({ target: { value } }) => setNextPasswordAgain(value)}
          />
          {nextPasswordAgainError ? <ErrorMessage resourceKey="passwordsDontMatch"/> : null}
        </label>
        {error ? <ErrorMessage resourceKey="changePasswordErrorMessage"/> : null}
        <button type="submit" className="btn login-container__submit">
          {loading ? <LoadingSpinnerButton/> : getResourceText('updatePassword')}
        </button>
      </form>
    </Fragment>
  );
};

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const { login: apiLogin, logout: apiLogout } = useApi();

  useEffect(() => {
    clearData(USER_KEY);
    const getData = async () => await apiLogout();
    getData().catch((e) => {
      console.log('error logging out', e);
    });
  }, [apiLogout]);

  const login = async (event) => {
    event.preventDefault();
    if (!validate()) return;

    if (usernameError || passwordError) return;

    setLoading(true);

    const loginTimeout = setTimeout(async () => {
      setLoading(false);
      setLoginError(true);
      setLoginErrorMessage('loginErrorTimeoutMessage');
    }, 5000);

    try {
      const { status, json } = await apiLogin({ username, password });

      setLoading(false);
      clearTimeout(loginTimeout);

      if (status === 200) {
        if (!json.isChangePassword) {
          saveData(USER_KEY, JSON.stringify(json));
          return await navigate('/dashboard');
        } else {
          setIsChangePassword(true);
        }
      }

      if (status === 400) {
        const { errors = [] } = json;

        if (errors.find(({ text }) => text.toLowerCase() === 'user is not active')) {
          setLoginErrorMessage('userNotActiveErrorMessage');
        }
        setLoginError(true);
      }

    } catch (e) {
      clearTimeout(loginTimeout);
      setLoading(false);
      setLoginError(true);
      console.error('error logging in', e);
    }
  };

  const validate = () => {
    let valid = true;

    if (!username.trim()) {
      setUsernameError(true);
      valid = false;
    }

    if (!password.trim()) {
      setPasswordError(true);
      valid = false;
    }

    return valid;
  };

  const updateUsername = (value) => {
    setUsernameError(false);
    setLoginError(false);
    setUsername(value);
  };

  const updatePassword = (value) => {
    setPasswordError(false);
    setLoginError(false);
    setPassword(value);
  };

  return (
    <div className="login-container">
      {isChangePassword ? <UpdatePassword/> : <Fragment>
        <h1 className="xlarge-heading login-container__main-heading">{getResourceText('loginMainHeading')}</h1>
        <h2 className="medium-heading">{getResourceText('loginSubHeading')}</h2>
        <StaticImage
          src="../images/peri-with-maraccas.png"
          alt={getResourceText('professorPeriWelcomeAltText')}
          title="Professor Peri"
          height={300}
          placeholder="blurred"
          className="login-image"
        />
        <form onSubmit={login}>
          <label className="login-container__label-container">
            <span className="login-container__label">{getResourceText('username')}</span>
            <input
              type="text"
              className="login-container__input"
              value={username}
              onChange={({ target: { value } }) => updateUsername(value)}
            />
            {usernameError ? <ErrorMessage resourceKey="usernameErrorMessage"/> : null}
          </label>
          <label className="login-container__label-container">
            <span className="login-container__label">{getResourceText('password')}</span>
            <input
              type="password"
              className="login-container__input"
              value={password}
              onChange={({ target: { value } }) => updatePassword(value)}
            />
            {passwordError ? <ErrorMessage resourceKey="passwordErrorMessage"/> : null}
            {loginError ?
              <ErrorMessage resourceKey={loginErrorMessage ? loginErrorMessage : 'loginErrorMessage'}/> : null}
          </label>

          <button type="submit" className="btn login-container__submit" disabled={usernameError || passwordError}>
            {loading ? <LoadingSpinnerButton/> : getResourceText('login')}
          </button>
        </form>
      </Fragment>}
    </div>
  );
};

export default Login;
